import React, { useState, useEffect, useRef } from 'react';
import {
    RemoteVideo,
    useMeetingManager,
    useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import { style } from 'styled-system';
import ChatRoom from 'components/ChatRoom';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Icon from 'components/Icon';

export const GuestPannel = React.memo(({ isFull }) => {
    const videoRef = useRef();
    const selectRef = useRef();
    const [isMuted, setIsMuted] = useState(true);

    useEffect(() => {
        if (!videoRef.current) return
        videoRef.current.muted = isMuted
    }, [isMuted, videoRef])

    useEffect(() => {
        if (!selectRef.current) return
        if (!videoRef.current) return
        var constraints = { audio: true, video: { width: 1280, height: 720 } };

        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (mediaStream) {
                var video = videoRef.current;
                video.srcObject = mediaStream;
                video.onloadedmetadata = function (e) {
                    video.play();
                };
            })
            .catch(function (err) { console.log(err.name + ": " + err.message); });

        navigator.mediaDevices.enumerateDevices().then(function (devices) {
            for (var i = 0; i < devices.length; i++) {
                var device = devices[i];
                if (device.kind === 'videoinput') {
                    var option = document.createElement('option');
                    option.value = device.deviceId;
                    option.text = device.label || 'camera ' + (i + 1);
                    selectRef.current.appendChild(option);
                }
            };
        });
    }, [videoRef])

    const setDevice = (e) => {
        var constraints = { audio: true, video: { 'deviceId': e.value, width: 1280, height: 720 } };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (mediaStream) {
                var video = videoRef.current;
                video.srcObject = mediaStream;
                video.onloadedmetadata = function (e) {
                    video.play();
                };
            })
            .catch(function (err) { console.log(err.name + ": " + err.message); });
    }

    return <div className={classnames(styles.discussionPanel, styles.wrapper)}>
        <div id="content" className={styles.chatContent}>
            <div className={styles.localVideo}>
                <select ref={selectRef} onChange={(e) => setDevice(e)}></select>
                <video ref={videoRef} />
            </div>
            <div
                className={styles.muted}
                onClick={() => {
                    setIsMuted(!isMuted)
                }}
            >
                {
                    isMuted ? <Icon name="CloseAudioOutput" /> :
                        <Icon name="OpenAudioOutput" />
                }
            </div>
            <div className={styles.chatRoomWrapper}>
                <ChatRoom />
            </div>

        </div>
    </div >
})


export default GuestPannel;